import React, {useState} from "react";
import { Helmet } from "react-helmet";
import {useLazyQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {CodeCheckerModuleQueryType, CodeCheckerModuleQueryVars} from "../graphql/types/checkCode.types";
import ModalCodesCheckHistory from "../components/ModalCodesCheckHistory";
import Heading from "../components/Heading/Heading";
import arrowsDownIcon from "../images/arrows-down.svg";
import skynameCodeBadge from "../images/skyname-code.jpg";
import InputGrooc from "../shared/Input/InputGrooc";
import photo1 from "../images/new-collection/1.jpg";
import photo2 from "../images/new-collection/2.jpg";
import photo3 from "../images/new-collection/3.jpg";
import photo4 from "../images/new-collection/4.jpg";
import photo5 from "../images/new-collection/5.jpg";
import photo6 from "../images/new-collection/6.jpg";
import photo7 from "../images/new-collection/7.jpg";
import photo8 from "../images/new-collection/8.jpg";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import mainBannerPc from "../images/main-banner.jpg";
import Navigation from "../shared/Navigation/Navigation";


interface ICheckState {
    bgClass: string;
    textClass: string;
    heading: string;
    description: string;
}

interface ICheckStateObject {
    ['success']: ICheckState;
    ['warning']: ICheckState;
    ['error']: ICheckState;
    ['default']: ICheckState;
}
function PageCode() {

    const checkCodeQuery = loader('../graphql/checkCode.graphql');

    let curState: 'default' | 'warning' | 'error' | 'success' = 'default';

    const [currentCode, setCurrentCode] = useState('');
    const [currentState, setCurrentState] = useState(0);
    const [validationState, setValidationState] = useState(0);
    const [isShowingCodesHistory, setShowCodesHistory] = useState(false);

    const openModalCodesHistory = () => setShowCodesHistory(true);
    const closeModalCodesHistory = () => setShowCodesHistory(false);

    const [getCheckResult, { loading, data }] = useLazyQuery<CodeCheckerModuleQueryType, CodeCheckerModuleQueryVars>(checkCodeQuery, {
        variables: {codeValue: currentCode},
        nextFetchPolicy: 'network-only',
    });

    const checkStates: ICheckStateObject = {
        success: {
            bgClass: 'bg-green-100',
            textClass: 'text-green-400',
            heading: 'Товар подлинный!',
            description: '',
        },
        warning: {
            bgClass: 'bg-amber-100',
            textClass: 'text-amber-400',
            heading: '*Товар подлинный!',
            description: 'Данный код уже проверялся ранее. Если Вы не проходили проверку и Вы стерли защитный слой на коде, то это подделка. О данном факте Вы можете сообщить нам на почту <br/>sale@bs-opt.com',
        },
        error: {
            bgClass: 'bg-red-100',
            textClass: 'text-red-400',
            heading: 'Код не найден!',
            description: 'Товар с данным кодом является подделкой. О данном факте Вы можете сообщить нам на почту sale@bs-opt.com',
        },
        default: {
            bgClass: 'bg-[#dcedf6]',
            textClass: '',
            heading: '',
            description: '',
        }
    };

    if (currentState && !loading && data) {
        if (data.CodeCheckerModuleQuery.checkCode.found) {
            if (data.CodeCheckerModuleQuery.checkCode.checkedList && data.CodeCheckerModuleQuery.checkCode.checkedList.length === 0) {
                curState = 'success';
            } else {
                curState = 'warning';
            }
        } else {
            curState = 'error';
        }
    }

    return (
        <div className="nc-PageCode">
            <Helmet>
                <title>Skyname / Winner One - школьные рюкзаки, ранцы, сумки, мешки для обуви и пеналы</title>
            </Helmet>

            <div className="container-fluid relative">
                <div
                    className={`nc-SectionHero relative`}
                    data-nc-id="SectionHero"
                >
                    <div className="relative bg-transparent">
                        <img className="hidden lg:block w-full max-h-full" src={mainBannerPc} alt="" />
                    </div>
                </div>
            </div>

            <div className="hidden lg:flex items-center justify-center container-fluid"> {/* sticky top-0 z-40 bg-[rgba(255,255,255,0.95)] m-0 p-0 w-full */}
                <Navigation />
            </div>

            <div className="relative container overflow-hidden">
                <Heading fontClass="mt-[3.13rem] lg:mt-[5.3rem] text-[13.85px] lg:text-[24.5px] font-story text-[#7e838d] uppercase" isCenter={true}>
                    Проверка SkyName / Winner One на подлинность по коду
                </Heading>

                <div className="mt-11 lg:mt-[4.6rem] text-center">
                    <div className="border-[#7e838d] border-[0.5px] text-[#7e838d] text-[13.85px] lg:text-[18.5px] font-story px-3 lg:px-[4.5rem] lg:py-2 inline-block">
                        Сотрите защитный слой на наклейке
                    </div>
                    <img className={`w-8 lg:w-9 m-auto cursor-pointer transition duration-300 mt-1 lg:mt-5`} src={arrowsDownIcon} alt="Стрелки" />
                    <img className="w-[10.45rem] lg:w-[23.3rem] lg:mt-[1.25rem] m-auto" src={skynameCodeBadge} alt="Бирка с защитным кодом" />
                </div>


                    {loading && currentState ?
                        <span className={`${loading ? 'block' : 'hidden'} mt-8 text-[13.85px] lg:text-[18.5px] m-auto font-story text-center text-neutral-6000 dark:text-neutral-400 max-w-lg`}>
                            <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 inline-block"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Проверка...
                        </span>
                        : ''}

                {
                    !currentState ?
                        <div className="mt-5 lg:mt-[3.5rem] flex flex-col items-center gap-5 lg:max-w-[440px] max-w-[190px] m-auto">
                            <InputGrooc rounded={'rounded-none'} className={`uppercase text-center font-story ${validationState ? "border-red-400" : ""}`} type="text" placeholder="Введите код" value={currentCode} onChange={(e) => {setCurrentCode(e.currentTarget.value);}} />
                            <ButtonPrimary
                                className="border-none rounded-none w-full bg-[#d1d2d3] text-black lg:mt-[1.25rem]"
                                sizeClass="px-4 lg:px-[1.15rem] py-0 lg:py-3"
                                fontSize="text-black text-[7.5px] lg:text-[16px] leading-[1.4rem] lg:leading-[1.95rem]"
                                onClick={() => {if (currentCode.length > 0) {setCurrentState(1); setValidationState(0); getCheckResult()} else {setValidationState(1);}}}
                            >
                                Проверить
                            </ButtonPrimary>
                        </div>
                        :
                        <div className="mt-5 flex flex-col items-start gap-3 lg:max-w-sm max-w-[290px] m-auto">
                            <h3 className={`text-[13.85px] lg:text-[24.5px] m-auto font-story !leading-tight font-semibold text-neutral-900 ${checkStates[curState].textClass}`}>
                                {checkStates[curState].heading}
                            </h3>

                            <span className="block font-story text-[13.85px] lg:text-[18px] leading-text-normal lg:leading-text-normal-pc text-[#7e838d]" dangerouslySetInnerHTML={{__html: checkStates[curState].description}}></span>

                            { curState === 'warning' && data && data.CodeCheckerModuleQuery.checkCode.checkedList ?
                                <>
                                    <span className="block font-story text-[13.85px] lg:text-[18px] leading-text-normal lg:leading-text-normal-pc text-[#7e838d]">
                                        Дата последней проверки: {data.CodeCheckerModuleQuery.checkCode.checkedList.slice(-1)}
                                    </span>
                                    {/*<span className="text-center m-auto text-[#7e838d] font-story text-base xl:text-lg inline-block">{data.CodeCheckerModuleQuery.checkCode.checkedList.slice(-1)}</span>*/}

                                    <ButtonPrimary
                                        className="rounded-none mx-auto bg-white border-[0.5px] border-[#d1d2d3] text-black font-story uppercase lg:w-full lg:mt-[1.25rem]"
                                        sizeClass="px-4 lg:px-[1.15rem] py-0 lg:py-3"
                                        fontSize="text-black text-[7.5px] lg:text-[11px] leading-[1.4rem] lg:leading-[1.95rem]"
                                        onClick={openModalCodesHistory}>
                                        Посмотреть историю проверки кода
                                    </ButtonPrimary>

                                    <ModalCodesCheckHistory show={isShowingCodesHistory} onCloseModalTransferToken={closeModalCodesHistory} code={currentCode} codeCheckHistory={data.CodeCheckerModuleQuery.checkCode.checkedList} />
                                </>
                                : <></> }

                            {
                                !loading ?
                                    <ButtonPrimary
                                        className="border-none rounded-none lg:w-full bg-[#d1d2d3] mx-auto text-black lg:mt-[1.25rem]"
                                        sizeClass="px-4 lg:px-[1.15rem] py-0 lg:py-3"
                                        fontSize="text-black text-[7.5px] lg:text-[16px] leading-[1.4rem] lg:leading-[1.95rem]"
                                        onClick={() => {setCurrentState(0); setCurrentCode('')}}>
                                        Проверить еще
                                    </ButtonPrimary>
                                : <></>
                            }
                        </div>
                }
            </div>

            <div className="container-fluid mt-[4.5rem] lg:mt-[17.5rem] lg:mb-[10.7rem]">
                <div className="grid grid-rows-1 grid-cols-3 lg:grid-cols-8 gap-x-2 lg:px-2">
                    <img src={photo1} alt="" />
                    <img src={photo2} alt="" />
                    <img src={photo3} alt="" />
                    <img src={photo4} className="hidden lg:inline-block" alt="" />
                    <img src={photo5} className="hidden lg:inline-block" alt="" />
                    <img src={photo6} className="hidden lg:inline-block" alt="" />
                    <img src={photo7} className="hidden lg:inline-block" alt="" />
                    <img src={photo8} className="hidden lg:inline-block" alt="" />
                </div>
            </div>

        </div>
    );
}

export default PageCode;
