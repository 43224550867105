import React from "react";
import { Link } from "react-router-dom";
import logoWhiteImg from "images/skyname-logo-gray.png";
import logoGrayImg from "images/skyname-logo-gray.png";
import twFocusClass from "../../utils/twFocusClass";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;

  onClick?: () => void;
  smallHeader?: boolean;
  isMainPage?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  img = logoGrayImg,
  imgLight = logoWhiteImg,
  className = "flex-shrink-0",
  onClick,
  smallHeader = false,
  isMainPage = false,
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className} relative left-1/2 -translate-x-1/2 -ml-6 ` + twFocusClass(false)}
      onClick={onClick ? onClick : void(1)}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {isMainPage || (!isMainPage && smallHeader) ?
          <img
              className={`transition-all duration-300 ${smallHeader ? 'block max-h-[2rem]' : 'hidden'} ` + twFocusClass(false)}
              src={imgLight}
              alt="Logo"
          />
      :
          <img
              className={`transition-all duration-300 mt-[0.95rem] block ${smallHeader ? 'hidden' : 'max-h-[2.3rem]'} ` + twFocusClass(false)}
              src={img}
              alt="Logo"
          />
      }
      {/*imgLight && (
        <img
          className="hidden max-h-8 sm:max-h-10 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )*/}
    </Link>
  );
};

export default Logo;
